<template>
  <div
    class="min-h-screen flex flex-col text-los-dark-gray"
    :class="{
      'overflow-hidden': !useRoute().meta.disableOverflow,
    }"
  >
    <header
      class="fixed md:relative shadow md:shadow-none flex flex-col z-20 w-full"
    >
      <NavigationContainer />
    </header>

    <main id="content" class="grow flex flex-col pt-[56px] md:pt-0">
      <NavigationBanner />
      <slot></slot>
    </main>

    <NavigationFooter />

    <ToastContainer class="fixed bottom-3 right-3 z-50" />
  </div>
</template>

<script setup></script>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
