<template>
  <div class="w-full h-[56px] md:h-auto">
    <div class="w-full h-full bg-white">
      <nav
        class="max-w-screen-2xl mx-auto grid grid-cols-[110px_1fr_110px] sm:grid-cols-[140px_1fr_140px] md:grid-cols-[145px_1fr_145px] md:grid-rows-[50px_100px] pr-3 md:pr-6 md:pl-3 lg:px-8 z-10 h-full"
      >
        <div
          class="col-start-1 col-span-1 row-span-1 row-start-1 md:row-start-2 flex items-center justify-center relative"
        >
          <NuxtImg
            class="absolute w-20 sm:w-28 md:w-36 top-0 left-0 md:top-auto md:left-auto"
            src="/img/Logo-Los.png"
            alt="Los Naaimachines Logo"
            aria-hidden="true"
          />
          <NuxtLink
            to="/"
            class="absolute w-16 sm:w-24 md:w-28 top-2 left-2 md:top-auto md:left-auto"
            title="Naar Home"
          >
            <span class="sr-only">Home</span>
            <div class="aspect-square rotate-45" />
          </NuxtLink>
        </div>
        <div
          class="col-start-2 max-[1200px]:col-span-2 md:row-start-2 flex items-center h-full"
        >
          <a class="sr-only" href="#content"> Verder naar content </a>
          <NavigationMain
            :showMobileMenu="showMobileMenu"
            @onClickBars="showMobileMenu = !showMobileMenu"
          />
        </div>
        <div class="hidden md:block row-start-1 col-start-2 col-span-2">
          <NavigationTop />
        </div>
      </nav>

      <transition
        enter-active-class="transition ease-out duration-200 opacity-0 "
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
      >
        <NavigationMobile
          v-show="showMobileMenu"
          @hide="showMobileMenu = false"
        />
      </transition>
    </div>
  </div>
</template>

<script setup>
const showMobileMenu = ref(false);
</script>

<style lang="scss" scoped></style>
