<template>
  <div
    v-if="banner && banner.show"
    class="warning relative w-full text-center px-8 py-4 md:py-3 text-sm md:text-lg border-b border-b-gray-200"
    :class="[showBanner ? '' : 'hidden']"
    tabindex="-1"
  >
    <strong class="mr-2">{{ banner.title }}</strong>
    <span>{{ banner.text }}</span>

    <NuxtLink v-if="banner.url" class="underline ml-2" :to="banner.url">
      lees meer →
    </NuxtLink>
    <button
      class="absolute right-0 top-0 py-4 px-2 sm:px-4 sm:py-3"
      title="Sluit Banner"
      alt="Sluit Banner"
      @click="showBanner = false"
    >
      <span class="sr-only">Sluit banner</span>
      <FontAwesome class="mr-1 text-lg" icon="times" />
    </button>
  </div>
</template>

<script setup>
const { find } = useStrapi();

const showBanner = ref(true);

const { data } = await useAsyncData('banner', () => find('banner'));

const banner = computed(() => {
  return data.value.data;
});
</script>

<style lang="scss" scoped>
.warning {
  background-color: #fff3cd;
  color: #856404;
}
</style>
