<template>
  <div>
    <div class="flex flex-col-reverse gap-y-2">
      <TransitionGroup
        enter-active-class="transition ease-out duration-200 opacity-0 "
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
      >
        <button
          v-for="toast in toasts"
          :key="toast.id"
          class="flex items-center w-[20rem] p-4 space-x-4 rtl:space-x-reverse text-gray-500 divide-x rtl:divide-x-reverse divide-gray-100 rounded-lg shadow"
          :class="{
            'bg-los-blue-darkest text-white': toast.type === 'success',
            'bg-los-pink-lightest text-los-pink-darkest':
              toast.type === 'failed',
          }"
          role="alert"
          @click="onClick(toast.id)"
        >
          <FontAwesome class="text-2xl" :icon="toast.icon" />
          <div class="text-left">
            <div class="ps-4 font-semibold">{{ toast.title }}</div>
            <div class="ps-4">{{ toast.text }}</div>
          </div>
        </button>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup>
const { toasts, removeToast } = useToastsStore();

const onClick = (id) => {
  removeToast(id);
};
</script>

<style lang="scss" scoped></style>
