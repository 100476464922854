<template>
  <div
    class="w-full h-full flex justify-end min-[1200px]:justify-center items-center md:gap-x-4 lg:gap-x-16 min-[1200px]:gap-x-20 xl:gap-x-24"
  >
    <div
      class="hidden md:inline relative max-lg:px-8"
      @mouseleave="showMachinesFlyOut = false"
    >
      <NavigationMainLink
        to="/machines"
        label="Machines"
        has-fly-out
        :fly-out-expanded="showMachinesFlyOut"
        @onShowFlyOut="showMachinesFlyOut = true"
        @onHideFlyOut="showMachinesFlyOut = false"
        @onPressEnter="showMachinesFlyOut = !showMachinesFlyOut"
        @click="() => onClickMachineLink('alle-machines')"
      />

      <transition
        enter-active-class="transition ease-out duration-200 opacity-0 "
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
      >
        <div
          v-show="showMachinesFlyOut"
          class="absolute z-10 -ml-4 pt-3 px-2 w-screen max-w-md sm:px-0 lg:ml-0 left-0 -translate-x-10"
        >
          <div
            class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden bg-white"
          >
            <div
              class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 sm:pr-2"
            >
              <NuxtLink
                to="/machines/alle-machines"
                class="-m-3 p-3 w-full flex items-start justify-between rounded-lg hover:bg-slate-50"
                @keydown.tab.exact="onTabMachineFlyout(0)"
                @click.prevent="() => onClickMachineLink('alle-machines')"
              >
                <div class="ml-2 text-base font-medium los-darkest-gray">
                  Alle machines
                </div>
                <FontAwesome
                  icon="chevron-right"
                  class="text-gray-700 ml-2 h-5 w-5 group-hover:los-darkest-gray"
                  aria-hidden="true"
                />
              </NuxtLink>

              <NuxtLink
                v-for="(type, index) in types"
                :key="`machine-filter-type-${index}`"
                :to="`/machines/${type.key}`"
                class="-m-3 p-3 w-full flex items-start justify-between rounded-lg hover:bg-slate-50"
                @keydown.tab.exact="onTabMachineFlyout(index + 1)"
                @click.prevent="() => onClickMachineLink(type.key)"
              >
                <div class="ml-2 text-base font-medium los-darkest-gray">
                  {{ type.name }}
                </div>
                <FontAwesome
                  icon="chevron-right"
                  class="text-gray-700 ml-2 h-5 w-5 group-hover:los-darkest-gray"
                  aria-hidden="true"
                />
              </NuxtLink>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <NavigationMainLink
      to="/reparaties"
      label="Reparaties"
      class="hidden md:inline max-lg:mx-8"
    />

    <div
      class="hidden md:inline relative max-lg:px-8"
      @mouseleave="showWorkshopsFlyOut = false"
    >
      <NavigationMainLink
        to="/workshops"
        label="Workshops"
        has-fly-out
        :fly-out-expanded="showWorkshopsFlyOut"
        @onShowFlyOut="showWorkshopsFlyOut = true"
        @onHideFlyOut="showWorkshopsFlyOut = false"
        @onPressEnter="showWorkshopsFlyOut = !showWorkshopsFlyOut"
        @click="showWorkshopsFlyOut = false"
      />

      <transition
        enter-active-class="transition ease-out duration-200 opacity-0 "
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
      >
        <div
          v-show="showWorkshopsFlyOut"
          class="absolute z-10 -ml-4 pt-3 px-2 w-screen max-w-md sm:px-0 lg:ml-0 right-0 translate-x-10 lg:left-0 lg:-translate-x-10"
        >
          <div
            class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden bg-white"
          >
            <div
              class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 sm:pr-2"
            >
              <NuxtLink
                to="/workshops"
                class="-m-3 p-3 w-full flex items-start justify-between rounded-lg hover:bg-slate-50"
                @keydown.tab.exact="onTabWorkshopFlyout(0)"
                @click="showWorkshopsFlyOut = false"
              >
                <div class="ml-2">
                  <p class="text-base font-medium los-darkest-gray">
                    Alle workshops
                  </p>
                </div>
                <FontAwesome
                  icon="chevron-right"
                  class="text-gray-700 ml-2 h-5 w-5 group-hover:los-darkest-gray"
                  aria-hidden="true"
                />
              </NuxtLink>
              <NuxtLink
                v-for="(workshop, index) in workshops"
                :key="`workshop-filter-type-${index}`"
                :to="
                  workshop.slug !== 'all'
                    ? `/workshops/${workshop.slug}`
                    : '/workshops'
                "
                class="-m-3 p-3 w-full flex items-start justify-between rounded-lg hover:bg-slate-50"
                @keydown.tab.exact="onTabWorkshopFlyout(index + 1)"
                @click="showWorkshopsFlyOut = false"
              >
                <div class="ml-2">
                  <p class="text-base font-medium los-darkest-gray">
                    {{ workshop.name }}
                  </p>
                </div>
                <FontAwesome
                  icon="chevron-right"
                  class="text-gray-700 ml-2 h-5 w-5 group-hover:los-darkest-gray"
                  aria-hidden="true"
                />
              </NuxtLink>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <NavigationMainLink
      to="/accessoires"
      label="Accessoires"
      class="hidden lg:inline"
    />

    <NavigationMainLink
      to="/inspiratie"
      label="Inspiratie"
      class="hidden lg:inline"
    />

    <div
      class="relative hidden md:inline lg:hidden max-lg:pl-8"
      @mouseleave="showMoreFlyOut = false"
    >
      <NavigationMainButton
        has-fly-out
        :fly-out-expanded="showMoreFlyOut"
        @onShowFlyOut="showMoreFlyOut = true"
        @onHideFlyOut="showMoreFlyOut = false"
        @onToggle="showMoreFlyOut = !showMoreFlyOut"
      >
        <span class="sr-only">Toon meer links</span>
        <span aria-hidden="true">Meer</span>
      </NavigationMainButton>

      <ClientOnly>
        <transition
          enter-active-class="transition ease-out duration-200 opacity-0 "
          enter-from-class="opacity-0 translate-y-1"
          enter-to-class="opacity-100 translate-y-0"
          leave-active-class="transition ease-in duration-150"
          leave-from-class="opacity-100 translate-y-0"
          leave-to-class="opacity-0 translate-y-1"
        >
          <div
            v-show="showMoreFlyOut"
            class="absolute z-10 -ml-4 pt-3 px-2 w-screen max-w-md sm:px-0 lg:ml-0 right-0 lg:translate-x-10"
          >
            <div
              class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden bg-white"
            >
              <div
                class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 sm:pr-2"
              >
                <NuxtLink
                  to="/accessoires"
                  class="-m-3 p-3 w-full flex items-start justify-between rounded-lg hover:bg-slate-50"
                  @keydown.shift.tab="showMoreFlyOut = false"
                  @click="showMoreFlyOut = false"
                >
                  <div class="ml-2">
                    <p class="text-base font-medium los-darkest-gray">
                      Accessoires
                    </p>
                  </div>
                  <FontAwesome
                    icon="chevron-right"
                    class="text-gray-700 ml-2 h-5 w-5 group-hover:los-darkest-gray"
                    aria-hidden="true"
                  />
                </NuxtLink>
                <NuxtLink
                  to="/inspiratie"
                  class="-m-3 p-3 w-full flex items-start justify-between rounded-lg hover:bg-slate-50"
                  @click="showMoreFlyOut = false"
                >
                  <div class="ml-2">
                    <p class="text-base font-medium los-darkest-gray">
                      Inspiratie
                    </p>
                  </div>
                  <FontAwesome
                    icon="chevron-right"
                    class="text-gray-700 ml-2 h-5 w-5 group-hover:los-darkest-gray"
                    aria-hidden="true"
                  />
                </NuxtLink>
                <NuxtLink
                  to="/over-ons"
                  class="-m-3 p-3 w-full flex items-start justify-between rounded-lg hover:bg-slate-50"
                  @click="showMoreFlyOut = false"
                >
                  <div class="ml-2">
                    <p class="text-base font-medium los-darkest-gray">
                      Over ons
                    </p>
                  </div>
                  <FontAwesome
                    icon="chevron-right"
                    class="text-gray-700 ml-2 h-5 w-5 group-hover:los-darkest-gray"
                    aria-hidden="true"
                  />
                </NuxtLink>
                <NuxtLink
                  to="/contact"
                  class="-m-3 p-3 w-full flex items-start justify-between rounded-lg hover:bg-slate-50"
                  @keydown.tab.exact="showMoreFlyOut = false"
                  @click="showMoreFlyOut = false"
                >
                  <div class="ml-2">
                    <p class="text-base font-medium los-darkest-gray">
                      Contact
                    </p>
                  </div>
                  <FontAwesome
                    icon="chevron-right"
                    class="text-gray-700 ml-2 h-5 w-5 group-hover:los-darkest-gray"
                    aria-hidden="true"
                  />
                </NuxtLink>
              </div>
            </div>
          </div>
        </transition>
      </ClientOnly>
    </div>

    <div class="md:hidden flex items-center gap-x-3 sm:gap-x-4">
      <div class="flex items-center">
        <a
          target="_blank"
          href="https://maps.app.goo.gl/XQEHdpQjWkuosmjz8"
          class="hover:underline"
        >
          <span class="sr-only">Google reviews</span>
          <FontAwesome
            v-for="index in 5"
            :key="index"
            icon="star"
            class="text-yellow-400 mb-[1px]"
            :class="{ 'hidden sm:inline': index > 1 }"
            aria-hidden="true"
          />
          <div class="ml-1 inline">
            <span class="hidden xl:inline">Beoordeling van </span
            ><span>4,8</span><span class="hidden lg:inline"> op Google!</span>
          </div>
        </a>
      </div>

      <button
        type="button"
        class="bg-white rounded-md p-2 inline-flex items-center justify-center hover:text-darkest-gray hover:bg-gray-100"
        :aria-expanded="showMobileMenu"
        @click="$emit('onClickBars')"
      >
        <span class="sr-only">Open menu</span>
        <FontAwesome icon="bars" class="h-6 w-6 text-2xl" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
const route = useRoute();

const machineStore = useMachineStore();
const { types } = storeToRefs(machineStore);

const workshopStore = useWorkshopStore();
const { workshops } = storeToRefs(workshopStore);

defineProps({
  showMobileMenu: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onClickBars']);

const currentPath = ref(route.path);

const showMachinesFlyOut = ref(false);
const showWorkshopsFlyOut = ref(false);
const showMoreFlyOut = ref(false);

watch(
  () => route.path,
  (p) => {
    currentPath.value = p;
  }
);

function onTabMachineFlyout(index) {
  if (types.value.length === index) {
    showMachinesFlyOut.value = false;
  }
}

function onTabWorkshopFlyout(index) {
  if (types.value.length === index) {
    showWorkshopsFlyOut.value = false;
  }
}

async function onClickMachineLink(key) {
  showMachinesFlyOut.value = false;

  await navigateTo(
    {
      name: 'machines-type',
      params: {
        type: key,
      },
    },
    {
      external: currentPath.value.includes('/machines'),
    }
  );
}
</script>
