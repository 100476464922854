<template>
  <footer class="w-full">
    <div
      class="w-full bg-los-purple-lightest relative overflow-hidden px-basic"
    >
      <NuxtImg
        aria-hidden="true"
        src="/img/Logo-Los-wit.png"
        class="absolute bottom-24 -right-24 sm:-bottom-16 md:bottom-6 lg:-bottom-20 md:right-10 lg:right-10 xl:right-16 lg:translate-x-3 lg:translate-y-3 opacity-60 w-96 md:w-[28rem]"
        atl="Los logo"
      />

      <div
        class="max-w-screen-xl mx-auto flex flex-col items-center py-12 pb-16 sm:pb-32"
      >
        <div class="flex gap-x-3 mb-12">
          <a
            href="https://wa.me/31713031232"
            target="_blank"
            class="bg-los-purple-darkest shadow h-10 w-10 rounded-full flex items-center justify-center pb-[.1rem]"
          >
            <span class="sr-only">Whatsapp</span>
            <FontAwesome class="text-white h-[1.6rem]" :icon="faWhatsapp" />
          </a>
          <a
            href="https://www.instagram.com/losnaaimachines/"
            target="_blank"
            class="bg-los-purple-darkest shadow h-10 w-10 rounded-full flex items-center justify-center"
          >
            <span class="sr-only">Instagram</span>
            <FontAwesome class="text-white h-[1.6rem]" :icon="faInstagram" />
          </a>
          <a
            href="https://www.facebook.com/LosNaaimachines"
            target="_blank"
            class="bg-los-purple-darkest shadow h-10 w-10 rounded-full flex items-center justify-center"
          >
            <span class="sr-only">Facebook</span>
            <FontAwesome
              class="text-white h-[1.6rem] pl-[.1rem]"
              :icon="faFacebook"
            />
          </a>
        </div>

        <div
          class="flex flex-col sm:flex-row sm:items-start xl:items-start justify-between w-full gap-y-10 gap-x-16 z-0"
        >
          <div class="flex gap-x-16 flex-wrap gap-y-8 order-2 w-full md:w-auto">
            <div class="hidden md:flex order-1 flex-col">
              <h5 class="text-2xl font-platform text-los-purple-darkest mb-1">
                Bekijk meer
              </h5>
              <div class="leading-relaxed">
                <div>
                  <NuxtLink to="/machines" class="hover:underline"
                    >Machines</NuxtLink
                  >
                </div>
                <div>
                  <NuxtLink to="/reparaties" class="hover:underline"
                    >Reparaties</NuxtLink
                  >
                </div>
                <div>
                  <NuxtLink to="/accessoires" class="hover:underline"
                    >Accessoires</NuxtLink
                  >
                </div>
                <div>
                  <NuxtLink to="/workshops" class="hover:underline"
                    >Workshops</NuxtLink
                  >
                </div>
                <div>
                  <NuxtLink to="/inspiratie" class="hover:underline"
                    >Inspiratie</NuxtLink
                  >
                </div>
                <div>
                  <NuxtLink to="/over-ons" class="hover:underline"
                    >Over ons</NuxtLink
                  >
                </div>
                <div>
                  <NuxtLink to="/contact" class="hover:underline"
                    >Contact</NuxtLink
                  >
                </div>
              </div>
            </div>
            <div class="order-3 md:order-2 w-full md:w-auto z-0">
              <h5 class="text-2xl font-platform text-los-purple-darkest mb-1">
                Openingstijden
              </h5>
              <div class="leading-relaxed">
                <div>Maandag: Gesloten</div>
                <div>Dinsdag: 9:00 – 18:00 uur</div>
                <div>Woensdag: 9:00 – 18:00 uur</div>
                <div>Donderdag: 9:00 – 18:00 uur</div>
                <div>Vrijdag: 9:00 – 18:00 uur</div>
                <div>Zaterdag: 9:00 – 16:00 uur</div>
                <div>Zondag: Gesloten</div>
              </div>
            </div>
            <div class="order-2 md:order-3">
              <h5 class="text-2xl font-platform text-los-purple-darkest mb-1">
                Kom in contact
              </h5>
              <div class="leading-relaxed">
                <a href="tel:(071)3031232"
                  ><div class="hover:underline">(071) 303 12 32</div></a
                >
                <a href="mailto:info@losnaaimachines.nl"
                  ><div class="hover:underline">info@losnaaimachines.nl</div></a
                >
                <br />
                <a
                  href="https://maps.app.goo.gl/XQEHdpQjWkuosmjz8"
                  target="_blank"
                >
                  <div class="hover:underline">
                    Langeweg 4-B | 2371EG<br />Roelofarendsveen
                  </div></a
                >
                <div class="italic">Gratis parkeren<br />voor de deur</div>
              </div>
            </div>
          </div>
          <div
            class="hidden sm:flex sm:flex-2 md:flex-2 justify-end order-2 z-10"
          >
            <div class="max-w-[32rem]">
              <h5 class="text-2xl text-los-purple-darkest mb-1 font-platform">
                Ontvang de LosBrief
              </h5>
              <p class="mb-4 text-basic">
                Met de Losbrief ben je altijd als eerste op de hoogte van de
                leukste aanbiedingen en de laatste nieuwtjes. Schrijf je direct
                in!
              </p>
              <NewsletterForm />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="bg-los-dark-gray text-slate-50 py-3 text-xs relative px-basic leading-relaxed"
    >
      <div
        class="w-full flex flex-row-reverse justify-center sm:justify-between items-end max-w-screen-xl mx-auto"
      >
        <div class="hidden sm:block">
          Een website van
          <NuxtLink
            external
            class="hover:underline"
            target="_blank"
            to="https://www.codezijn.dev"
            >Codezijn</NuxtLink
          ><br />
          en
          <NuxtLink
            external
            target="_blank"
            class="hover:underline"
            to="https://www.studiomyrtelcharlotte.nl"
            >Studio Myrtel Charlotte</NuxtLink
          >
        </div>
        <div>
          <div>
            <NuxtLink class="hover:underline" to="/privacyverklaring"
              >Privacyverklaring</NuxtLink
            >
            -
            <NuxtLink class="hover:underline" to="/cookies">Cookies</NuxtLink>
            -
            <NuxtLink class="hover:underline" to="/algemene-voorwaarden"
              >Algemene Voorwaarden</NuxtLink
            >
            -
            <NuxtLink class="hover:underline" to="/annuleringsbeleid"
              >Annuleringsbeleid</NuxtLink
            >
          </div>
          <div class="pointer-events-none select-none">
            © Los Naaimachines {{ currentYear }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import dayjs from 'dayjs';
import {
  faInstagram,
  faFacebook,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

const currentYear = computed(() => {
  return dayjs().format('YYYY');
});
</script>

<style lang="scss" scoped></style>
