<template>
  <NuxtLink
    :to="to"
    class="text-base font-medium hover:los-darkest-gray text-wrap"
    active-class="los-darkest-gray underline"
    :aria-expanded="flyOutExpanded"
    @mouseenter="$emit('onShowFlyOut')"
    @focus="isFocused = true"
    @keydown.enter="onkeyDownEnter"
    @keydown.shift.tab="$emit('onHideFlyOut')"
  >
    <div class="py-3">
      {{ label }}
    </div>
  </NuxtLink>
</template>

<script setup>
const isFocused = ref(false);

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  to: {
    type: String,
    default: '',
  },
  flyOutExpanded: {
    type: Boolean,
    default: false,
  },
  hasFlyOut: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onShowFlyOut', 'onHideFlyOut', 'onPressEnter']);

function onkeyDownEnter(e) {
  if (isFocused.value && props.hasFlyOut) {
    e.preventDefault();
    emit('onPressEnter');
  }
}
</script>

<style lang="scss" scoped></style>
