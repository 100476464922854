<template>
  <div class="w-full h-full flex items-center gap-x-8 justify-between text-sm">
    <div class="flex items-center justify-between gap-x-8 max-lg:w-full">
      <div>
        Vandaag
        <span v-if="isOpenToday"
          >geopend: {{ currentDay.opening }} - {{ currentDay.closing }}</span
        ><span v-else>gesloten</span>
      </div>
      <div class="flex items-center">
        <a
          target="_blank"
          href="https://maps.app.goo.gl/XQEHdpQjWkuosmjz8"
          class="hover:underline"
        >
          <span class="sr-only">Google reviews</span>
          <FontAwesome
            v-for="index in 5"
            :key="index"
            icon="star"
            class="text-yellow-400 mb-[1px]"
            aria-hidden="true"
          />
          <div class="ml-3 inline">
            <span class="hidden xl:inline">Beoordeling van </span
            ><span>4,8</span><span class="hidden md:inline"> op Google!</span>
          </div>
        </a>
      </div>
    </div>

    <div class="hidden lg:flex justify-end gap-6 flex-1 font-medium">
      <NuxtLink
        class="px-1"
        active-class="los-darkest-gray underline"
        to="/over-ons"
        >Over ons</NuxtLink
      >
      <NuxtLink
        class="px-1"
        active-class="los-darkest-gray underline"
        to="/contact"
        >Contact</NuxtLink
      >
    </div>

    <!-- <NavigationSearchContainer /> -->
  </div>
</template>

<script setup>
import dayjs from 'dayjs';

const dailyOpeningTimes = {
  1: {
    opening: null,
    closing: null,
  },
  2: {
    opening: '9:00',
    closing: '18:00',
  },
  3: {
    opening: '9:00',
    closing: '18:00',
  },
  4: {
    opening: '9:00',
    closing: '18:00',
  },
  5: {
    opening: '9:00',
    closing: '18:00',
  },
  6: {
    opening: '9:00',
    closing: '16:00',
  },
  7: {
    opening: null,
    closing: null,
  },
};

const currentDay = computed(() => {
  return dailyOpeningTimes[dayjs().isoWeekday()];
});

const isOpenToday = computed(() => {
  return !!currentDay.value.opening;
});
</script>

<style></style>
